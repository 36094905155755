import React from "react"


const Legend = () => {
  return (
    <div className="legend-position">
        <div className={"info legend leaflet-control check"}>
          <div>
            <h3>Prediction Labels</h3>
            <div>
              <i style={{ border: "solid 1px #00ffff" }}></i>

              <span> electricity poles</span>
            </div>
            <div>
              <i style={{ border: "solid 1px #ff00ff" }}></i>
              <span> telecommunication poles</span>
            </div>
            <div>
              <i style={{ border: "solid 1px #ffff00" }}></i>
              <span> lighting poles</span>
            </div>
          </div>
          <div>
            <h3>Validation Labels</h3>
            <div >
              <i style={{ border: "dashed 1px #00ffff" }}></i>
              <span> electricity poles</span>
            </div>
            <div>
              <i style={{ border: "dashed 1px #ff00ff" }}></i>
              <span> telecommunication poles</span>
            </div>
            <div>
              <i style={{ border: "dashed 1px #ffff00" }}></i>
              <span> lighting poles</span>
            </div>
          </div>
        </div>
    </div>
  )
}
export default Legend