/* eslint-disable react-hooks/exhaustive-deps */
import L from "leaflet"
import { useEffect } from "react"
import { useMap } from "react-leaflet"
import { useFetch } from "../../utils/hooks"

const VectorLayer = data => {
  const map = useMap()
  const [d, loading] = useFetch(data.url)
  if(data.center !== undefined && data.zoom !== undefined) {
    map.setView(data.center, data.zoom);
  }
  useEffect(() => {
    const geoJSONStyle = (feature, type) => {
      if (!loading) {
        let color = "white"
        let dashArray = null

        if (type === "labels") {
          switch (feature.properties.label) {
            case "e":
              color = "#00ffff"
              break
            case "t":
              color = "#ff00ff"
              break
            default:
              color = "#ffff00"
          }
          dashArray = "3"
        } else if (type === "predictions") {
          switch (feature.properties.class_name) {
            case "e":
              color = "#00ffff"
              break
            case "t":
              color = "#ff00ff"
              break
            default:
              color = "#ffff00"
          }
          dashArray = null
        }
        return {
          weight: 2,
          opacity: 1,
          color: color,
          dashArray: dashArray,
          fill: false,
        }
      }
    }

    const onEachFeature = (feature, layer) => {
      // does this feature have a property named popupContent?
      if (feature.properties && feature.properties.score) {
        layer.bindPopup("Score: " + feature.properties.score.toFixed(2))
      }
    }

    L.geoJSON(d, {
      style: function(feature) {
        return geoJSONStyle(feature, data.type)
      },
      onEachFeature: onEachFeature,
    }).addTo(map)
  },[loading])
  return null
}
export default VectorLayer
