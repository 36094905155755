import React, { useState } from "react"
import Layout from "../components/Layout"
import { MapContainer } from "react-leaflet"
import { GoogleLayer } from "react-leaflet-google-v2"
import Legend from "../components/Demo/Legend"
import VectorLayer from "../components/Demo/VectorLayer"
import Dropdown from "../components/Demo/Dropdown"


const DemoPage = () => {
  
  const initZoom =
    typeof window !== "undefined" &&
    window.location.search.match(/zoom=([^&]*)/i)
      ? window.location.search.match(/zoom=([^&]*)/i)[1]
      : 7
  const initPosition =
    typeof window !== "undefined" &&
    window.location.search.match(/position=([^&]*)/i)
      ? window.location.search.match(/position=([^&]*)/i)[1]
      : "39.0742, 21.8243"

  const [bounds, setBounds] = useState({
    zoom: initZoom,
    position: [initPosition.split(",")[0], initPosition.split(",")[1]],
  })

  const changeZoom = (e) => {
    setBounds(JSON.parse(e))
  }

  return (
    <Layout titlePage="Demo">
      <>
        {typeof window !== "undefined" ? (
          <MapContainer
            center={bounds.position}
            zoom={bounds.zoom}
            zoomControl={true}
            className={"mapStyle"}
          >
            {/* <ChangeView center={bounds.position} zoom={bounds.zoom}/> */}
            <GoogleLayer
              googlekey={process.env.GOOGLE_MAPS_API_KEY}
              maptype={"HYBRID"}
            />
            <VectorLayer
              center={bounds.position} zoom={bounds.zoom}
              url={"/koropi/val_labels_new.geojson"}
              name={"Koropi labels data"}
              type={"labels"}
            />
            <VectorLayer
              url={"/koropi/val-koropi.geojson"}
              name={"Koropi validation data"}
              type={"predictions"}
            />

            <VectorLayer
              url={"/palairos/val_labels_new.geojson"}
              name={"Palairos labels data"}
              type={"labels"}
            />
            <VectorLayer
              url={"/palairos/val-labels_predict.geojson"}
              name={"Palairos validation data"}
              type={"predictions"}
            />

            <VectorLayer
              url={"/tinos/val_labels_new.geojson"}
              name={"Tinos labels data"}
              type={"labels"}
            />
            <VectorLayer
              url={"/tinos/tinos-predictions.geojson"}
              name={"Tinos validation data"}
              type={"predictions"}
            />
            <Dropdown changeSelect={e => changeZoom(e)} />
            <Legend /> 
          </MapContainer>
        ) : null}
      </>
    </Layout>
  )
}

export default DemoPage
