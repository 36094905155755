import { useState, useEffect } from "react"

function useFetch(url) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const urlLink = url

  const fetchUrl = async urlLink => {
    const response = await fetch(urlLink)
    const json = await response.json()
    setData(json)
    setLoading(false)
  }

  useEffect(() => {
    fetchUrl(urlLink)
  }, [urlLink])
  return [data, loading]
}
export { useFetch }
