import React, { useState } from "react"
import PropTypes from "prop-types"

const Dropdown = ({ changeSelect }) => {
  const options = [
    { label: "Select area", value: { zoom: 7, position: [39.0742, 21.8243] } },
    { label: "Tinos", value: { zoom: 15, position: [37.5412033, 25.1658895] } },
    {
      label: "Palairos",
      value: { zoom: 15, position: [38.7788806, 20.879809] },
    },
    {
      label: "Koropi",
      value: { zoom: 15, position: [37.9022532, 23.8749322] },
    },
  ]

  const [selectedOption, setSelectedOption] = useState(options[0].value)
  const loseFocus = () => {
    document.getElementById("lose-focus").blur();
  }
  return (
    <div className = "demo-areas">
      <div className={"size-of-demo legend leaflet-control"}>
        <h3>Demo areas</h3>
        <select id="lose-focus"
          value={selectedOption}
          onChange={e => {
            setSelectedOption(e.target.value)
            changeSelect(e.target.value)
          }}
          onBlur={e => {}}
        >
          {options.map((o) => (   
              <option onClick = {() => loseFocus()} key={o.label} value={JSON.stringify(o.value)}>
                  {o.label}
              </option>
          ))}
        </select>
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  changeSelect: PropTypes.func.isRequired,
}

export default Dropdown
